import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { WorkSectionHeader, Nav, Centered, Jumbotron } from "../../components";
import GlobalLayout from "../../layouts/GlobalLayout";
import { Caption } from "../../style";

const accent = "#AAFF00";
const accentComplement = "blue";

const Stake = () => {
  const imgStyleOutline = {
    boxShadow: "0px 4px 32px #00000015",
    maxWidth: "1280px",
    placeSelf: "center",
    border: "1px solid black",
    marginTop: "3.33em",
  };
  return (
    <GlobalLayout>
      <Nav />
      <WorkSectionHeader
        workName="BitDAO Staking"
        who="BitDAO"
        role="Product Design"
      />
      <CenteredContainer>
        <P style={{ maxWidth: "560px", padding: "1.11rem" }}>
          I worked with the team at&nbsp;
          <Link target="_blank" href="https://windranger.io/">
            Windranger
          </Link>
          &nbsp;on a staking product for{" "}
          <Link target="_blank" href="https://www.bitdao.io">
            BitDAO
          </Link>
          . I designed this product alongside the ever talented{" "}
          <Link target="_blank" href="https://twitter.com/pimship">
            Pimentel
          </Link>
          . Together we created the interface through rapid prototyping &amp;
          user testing with help from{" "}
          <Link target="_blank" href="https://twitter.com/midroni">
            Cooper
          </Link>{" "}
          &amp;
          <Link target="_blank" href="https://www.octavioamu.com/">
            &nbsp;Octavio
          </Link>
          .
        </P>
      </CenteredContainer>

      {/* MUTUALISM */}

      <Jumbotron textSize="clamp(80px, 24vw, 400px)" color={accent}>
        MUTUALISM +/+
      </Jumbotron>
      <SplitContainer>
        <LeftSplit>
          <Content>
            <h3 style={{ textAlign: "center" }}>THE BITDAO ECOSYSTEM</h3>
            <StaticImage
              style={{ margin: "3.33rem 0" }}
              src="../../images/work/staking/ecosystem.png"
              alt="bitdao ecosystem"
            />
            <Link
              target="_blank"
              href="https://snapshot.org/#/bitdao.eth"
              style={{ textDecoration: "underline" }}
            >
              &amp; GROWING
            </Link>
          </Content>
        </LeftSplit>
        <RightSplit>
          <Content>
            <P>
              BitDAO is an investment DAO with $2B+ in assets.
              <br />
              <br />
              Investments range from gaming, layer 2 infrastructure, education,
              &amp; beyond.
            </P>
            <P
              style={{
                fontWeight: "bold",
                height: "32px",
                marginBottom: "-4px",
                width: "100%",
              }}
            >
              INVESTMENTS
            </P>
            <GridRow>
              <P>Game7</P>
              <P>$500M</P>
            </GridRow>
            <GridRow>
              <P>ZkDAO</P>
              <P>$200M</P>
            </GridRow>
            <GridRow>
              <P>EduDAO</P>
              <P>$33M</P>
            </GridRow>
            <GridRow>
              <P>AfricaDAO</P>
              <P>$20M</P>
            </GridRow>
            <GridRow>
              <P>PleasrDAO</P>
              <P>$6.5M</P>
            </GridRow>
          </Content>
        </RightSplit>
      </SplitContainer>

      {/* BIT TOKEN */}

      <Jumbotron textSize="clamp(64px, 20vw, 160px)" color={accent}>
        $BIT TO THE MOON
      </Jumbotron>

      <CenteredContainer>
        <StaticImage
          style={{
            minWidth: "320px",
            maxWidth: "888px",
          }}
          src="../../images/work/staking/uponly.png"
          alt="BIT token up only (lololol)"
        />

        <P style={{ maxWidth: "560px", marginTop: "7.77vmin" }}>
          The $BIT token is the foundation that the BitDAO ecosystem is built
          on.
          <br />
          <br />
          SubDAOs (Autonomous Entities or 'AEs') are created; more products are
          made by these AEs; they tokenize; and the value captured by all those
          tokens flows back to original source, the $BIT token.
          <br />
          <br />
          One big, mutualistic self-referential positive feedback loop. In
          theory...
        </P>
      </CenteredContainer>

      {/* PROBLEM */}

      <Jumbotron textSize="clamp(64px, 20vw, 160px)" color={accent}>
        THE PROBLEM
      </Jumbotron>

      <FullWidthContainer>
        <FullWidthCaption
          left
          style={{
            maxWidth: "420px",
            margin: "0 auto",
          }}
        >
          <P>
            <strong>Token distribution.</strong>
            <br />
            <br />
            For the BitDAO core team, the problem is bootstrapping liquidity for
            new tokens &amp; reflecting DAO values in how that gets done. A
            standard ICO model for each AE doesn't reflect BitDAO's thesis of
            mutualism. Something new is needed.
            <br />
            <br />
            For $BIT holders, the problem is utility. Why do I need to hold the
            $BIT token if the projects I'm specifically interested in using has
            its own token? Interviews &amp; lurking on the community discord
            &amp; telegram channel suggested this sentiment was true.
            <br />
            <br />
          </P>
        </FullWidthCaption>
        <ImageContainer caption>
          <StaticImage
            alt="lolololololololololololololololol"
            style={{}}
            src="../../images/work/staking/viewDAOs.png"
          />
          <ImageCaption>
            Each AE distributes the tokens of the other AEs. Gotta collect em
            all.
          </ImageCaption>
        </ImageContainer>
      </FullWidthContainer>

      {/* SOLUTION */}

      <Jumbotron textSize="clamp(64px, 20vw, 160px)" color={accent}>
        THE SOLUTION
      </Jumbotron>

      <FullWidthContainer>
        <ImageContainer>
          <StaticImage
            alt="lolololololololololololololololol"
            src="../../images/work/staking/DAO.png"
          />
        </ImageContainer>
        <FullWidthCaption right>
          <P>
            <strong>Whales 🤝 Staking</strong>
          </P>
          <P />
          <P>The solution was *suggested* to us. It happens, ya know?</P>
          <P>
            The method was a batch auction. A batch auction has a set amount of
            tokens that are divided amongst all the contributors to the pool,
            weighted according to their contribution to the pool. This token
            distribution method made sense for BitDAO's structure because of
            numerous highly incentivized community members itching to deploy
            capital…
          </P>
        </FullWidthCaption>
      </FullWidthContainer>

      {/* REWARD POOLS */}

      <Jumbotron textSize="15vw" color={accent}>
        REWARD POOLS
      </Jumbotron>
      <CenteredContainer>
        <P style={{ maxWidth: "560px", marginTop: "-3.33rem" }}>
          I thought it was a good idea to rename it to reward pools. It's not
          quite staking, not quite an auction. It's a pool you put capital in
          and get rewards from. Getting the language right is important.
        </P>
        <P>
          So instead of being able to take your tokens out whenever you like in
          traditional staking products; you have to commit to locking your $BIT
          tokens for a certain amount of time.
        </P>
        <P>Buy $BIT, hold $BIT.</P>
        <P>
          In return for that sacrifice of liquidity, you get the chance to
          receive more tokens than you would in a normal staking pool. As long
          as you bring enough size that is actual size.
        </P>
      </CenteredContainer>

      <ShowcaseContainer>
        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/staking/pool.png"
        />

        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/staking/stakeModal1.png"
        />

        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/staking/stakeModal2.png"
        />

        <StaticImage
          alt="lolololololololololololololololol"
          style={imgStyleOutline}
          src="../../images/work/staking/DAODetailModal.png"
        />
      </ShowcaseContainer>
    </GlobalLayout>
  );
};

const ShowcaseContainer = styled.section`
  display: flex;
  flex-flow: column;
  padding: 3.33em 0;
  // justify-content: center;
`;

const Section = styled.section;

const CenteredContainer = styled.section`
  height: auto;
  width: 100vmin;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SplitContainer = styled.div`
  display: flex;
  padding: 0 10vmin;
  max-width: 1024px;
  height: auto;
  margin: 10vmin auto;
  justify-content: space-between;
  gap: 8.88rem;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 3.33rem;
  } ;
`;
const FullWidthContainer = styled.div`
  // width: 100vw;
  height: auto;
  display: flex;
  // margin: 3.33rem 0;
  @media (max-width: 1024px) {
    flex-direction: column;
    // padding: 0 1.88rem;
  }
`;
const FullWidthCaption = styled.div`
  max-width: 560px;
  padding: 0 2.22rem;

  padding: ${(props) =>
    props.right ? "0 2.22rem 0 1.11rem" : "0 1.11rem 0 2.22rem"};

  @media (max-width: 800px) {
    padding: 0;
  }
`;
const ImageContainer = styled.div`
  width: 55%;
  padding: 2.22rem;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
`;
const LeftSplit = styled.div``;
const RightSplit = styled.div`
  flex-shrink: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GridRow = styled.div`
  width: 100%;
  display: flex;
  height: 32px;
  justify-content: space-between;

  &p:last-of-type {
    color: pink;
    flex-shrink: 1;
  }
`;

// typography

const P = styled.p`
  padding: 0 1.11rem;
  max-width: 560px;
  margin: 0 auto;
`;
const ImageCaption = styled(Caption)`
  text-align: center;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color: ${accentComplement};
    text-decoration: underline;
  }
`;

export default Stake;
